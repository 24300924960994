export const environment = {
  production: false,
  apiUrl: 'https://stg-api.abctalkies.com/api/v1',

  // apiUrl: 'http://localhost:4000/api/v1',
  ws_url: 'https://stg-api.abctalkies.com',
  captchaKey: '6LfONCgaAAAAAEuRl3K9PIYpsLZKpw5ET8IlmtPl',
  googleClientId:
    '293530915166-g0e358ktee6527ve304nss4i50ruoqg6.apps.googleusercontent.com',
  facebookClientId: '2549438418688501',
  linkedinClientId: '86c61u4o6f1qjv',
  linedinclientSecret: 't57XjdJRpuekXkCB',
  razorpayKey: 'rzp_test_Zx5m1yO3GV78Xv',
  logoUrl: 'https://stg.abctalkies.com/assets/images/abc-logo.svg',
  paymentUrl: 'https://test.cashfree.com/billpay/checkout/post/submit',
  cdnUrl: 'https://d3e49f5kxkkuec.cloudfront.net',
  // cdnUrl: 'https://d2ab47s2j0f4dc.cloudfront.net',
  hostUrl: 'https://stg-ssr.abctalkies.com',
  paymentMode: 'sandbox',
  secretKey : `sGCMqP6KEpiPmTG7NWicMyddRYIfW05PbGlzYHA4CoI=`,
  privateKey : `-----BEGIN RSA PRIVATE KEY-----
  MIIEpAIBAAKCAQEA0hziinItrK6cim9KLvyr9lw8KKZv4T6UVq6FE7mTLM9kbH+E
  0ZQwSUKk1AzxL3yKmxnYe0THB8pNClb3WILcUUufDhxYEzUE97HBYIeOBbOxmuZL
  B/UgkWe8UruPVuvlFlREujicJCqlBfGqecNNYUIpZiS18pVbd4lpqBhY5IdKAAjU
  dPwZNGfTfBYMiDqe4awE67anOKa3KpBnWljSC3DCh66YvPMRm0glG6L6tnlCAPQ9
  xtauHQha60rOpdH3pNHsuq8+2/9PqJhEeENTybijyvSj+8KH4pHp+xJ3/fXKbQk9
  wdsevdBmyrAZrxG69oxVdaXZIOJQJrZvYU/xzwIDAQABAoIBADhTmAOnYkk1si+G
  cCuyfuMXjOUU4U9P+HJYbMKB8EuF+ZUAhnmssVkibY+PPKUPTRURU7CQGo8jE44f
  DEYFJs0Aac5dmNrlTsScOYwIKvGqX4V4EUs70pRIa/r93wpdSNe4lYganvkvGXhD
  dgEYc0kMdI62brRuh8u+4b03cbUpdzZPxXTIWns4fExmxO7CBLZbqrfbgFF32sXZ
  AQcsAJs080Bv65ruSMeagCvXo7i9sRHzQpJUZWnQsMXhbaGT6kN6eafWWJ5Hsx7c
  0U6Syhe1CZj8OOEUJlqdBvYD62qGwRS/sfoisEhNF4CzjT4qYSwcBd4qanTUbeG8
  zRtYAkECgYEA68oLbxSNQn9ueOTh5oUkY6aOimp2a0zj8nawJJ/uA0kN5w/Ut+Y2
  Ew6+VT5yJOccpYYtUREurj5AhgjdnWgzS0nNkBciGiqljdb5CKZXVdB8y8IbhNpe
  NcmhrJkmmtY97dUIwUp9BsiEtjnilF2QCZb80LCmfoCKro4RljGY368CgYEA5B9r
  Xhy5R1kPVYk+W6QC0Nd46URTa/KiyAADbC4y6oOmPfaaTcoMZYZTsrd/fxd7l5p+
  WlWH8l/SJ2gvvQzuA+wXRfJ0NJwhY+Kc4p/eX3nsix0Aq2qy4cHtwRrUoZizP5Um
  M0Nw4l2iTaUzm1qiNe8n+gGJTeb0o6X7K4LXd+ECgYEA1txeXvKTZq1ItdGcKLva
  1RPJgEhTcKO4dBHDoRw7f7LqgEvkw098pxEgT/1/djFD4XHLibvmldEUaw9ZIz74
  vvqZ31u1L7L8+auTc1Zg70n07pgLC50RjGIYeXd1vlLMTBV8zOZb1/qRNJy65Af+
  YT69Uk4m6c+okDfVvs+jRvECgYEAzqR/d+MBGtZcA5ms+Qpa2p81EekowDIsrosE
  jqxSxwi2QI7wO+L408aYbt22CoRVGoGCT4YG/dE65yODivb5bGgitOPpy/ZacMbI
  Yql6oHYrheChyKWAoOww2KnbTv6p8MDEt88xpHw5F6i8VNAG/GBFkDxwCjdaCBvE
  qCd/00ECgYBD+Q7OgsGP22I6w/ITUPktq5VKuXBW6hlrT3gf8xfWWVRKOnGRPwdG
  343lRMESlnPwJSlewCsIft1A3NRQaHP8r8xPQp011qFDzg0BkKXp/g+8sRpKnwnR
  o9up6vS5dO9yU8vEu6TfJK/YYtcTGllMCHF9O68F/hAwfWj0rjkC7g==
  -----END RSA PRIVATE KEY-----`,
  firebase: {
    apiKey: 'AIzaSyATem9PtcP2LhgdbpfCtHwnlqidjEkQLBw',
    authDomain: 'abctalkies-def5c.firebaseapp.com',
    projectId: 'abctalkies-def5c',
    storageBucket: 'abctalkies-def5c.appspot.com',
    messagingSenderId: '108798226604',
    appId: '1:108798226604:web:06f8ee93c99e944173af09',
    measurementId: 'G-K1PQ5G50M0',
    vapidKey:
      'BMiTz6iWDu7LVtzeK_e311na236S00oq3ip0GaQVhHaB86TchlIUbBfkWTMWXofGA6eAhAdxQIicIGJorZbH4TE',
  },
  jioConfig: {
    reqType: "stg",
    logLevel: 'debug',  //only console error
    initialUnMute: true,
  },
  jioConfigKey: {
    preRoll: 'niwaqh2g',
    midRoll: 'niwaqh2g',
    dataSourcePackage: 'stg-ssr.abctalkies.com'
  }
};
