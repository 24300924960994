import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { Path } from '@core/enums/path.enum';
import { AuthGuard, NoAuthGuard, RoleGuard } from '@core/guards';
import { NotFoundPage } from '@public/pages/not-found/not-found.page';
import { LayoutComponent } from './layout/components/layout/layout.component';

const routes: Routes = [
  // Public
  {
    path: '',
    component: LayoutComponent,
    loadChildren: () =>
      import('./public/public.module').then((m) => m.PublicModule),
  },
  // Auth
  {
    path: Path.Auth,
    component: LayoutComponent,
    canActivate: [NoAuthGuard],
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: Path.Static,
    component: LayoutComponent,
    loadChildren: () =>
      import('./static/static.module').then((m) => m.StaticModule),
  },
  {
    path: Path.App,
    children: [
      {
        path: Path.Profile,
        component: LayoutComponent,
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./pages/profile/profile.module').then((m) => m.ProfileModule),
      },
      {
        path: Path.Filmmaker,
        component: LayoutComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: { roles: ['FILM_MAKER'] },
        loadChildren: () =>
          import('./pages/filmmaker/filmmaker.module').then(
            (m) => m.FilmmakerModule
          ),
      },
      {
        path: '',
        component: LayoutComponent,
        loadChildren: () =>
          import('./pages/movie-detail/movie-detail.module').then(
            (m) => m.MovieDetailModule
          ),
      },
      {
        path: Path.IAMFILMMAKER,
        component: LayoutComponent,
        loadChildren: () =>
          import('./pages/iamfilmmaker/iamfilmmaker.module').then(
            (m) => m.IamfilmmakerModule
          ),
      },
      {
        path: Path.SEARCH,
        component: LayoutComponent,
        loadChildren: () =>
          import('./pages/search/search.module').then((m) => m.SearchModule),
      },
    ],
  },
  // Not found page
  {
    path: '**',
    component: NotFoundPage,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      initialNavigation: 'enabledBlocking',
      onSameUrlNavigation: 'reload',
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
