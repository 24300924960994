import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { AppConstant, DeviceName } from '@app/core/constant/app-constant';
import { Path } from '@app/core/enums';
import { ProfileModel, User } from '@app/core/models/user.model';
import { AuthService } from '@app/core/services/auth.service';
import { LocalStorageService } from '@app/core/services/local-storage.service';
import { ToasterService } from '@app/core/services/toaster.service';
import { UploadFilmService } from '@app/pages/shared/services/upload-film.service';
import { ViewerProfileService } from '@app/pages/shared/services/viewer-profile.service';
import { WindowRefService } from '@app/pages/shared/services/windowref.service';
import { environment } from '@environments/environment';
import { getMessaging, getToken, onMessage } from '@firebase/messaging';
import { DeviceDetectorService } from 'ngx-device-detector';
import { of, Subject, Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged, map,
  switchMap, take, takeUntil, tap
} from 'rxjs/operators';
import { NotificationModel } from './../../../core/models/notification.model';
import { NotificationService } from './../../../core/services/notification.service';
import { BigShortService } from '@app/public/shared/services/big-short.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarComponent implements OnInit, OnDestroy {
  @Output() logout = new EventEmitter<void>();
  @ViewChild('movieSearchInput', { static: true }) movieSearchInput: ElementRef;
  cdnUrl = environment.cdnUrl;
  path = Path;
  isLoggedIn: boolean;
  user: User;
  isSticky: boolean = Boolean(false);
  search: string;
  errorMessage: string;
  isSearch: boolean = Boolean(true);
  focus: boolean = Boolean(false);
  userProfile: ProfileModel;
  switchrolesub: Subscription;
  suggestionsSub: Subscription;
  noResult: boolean = Boolean(false);
  currentUrl: string;
  unSubscribeAll: Subject<void> = new Subject<void>();
  queryField: FormControl = new FormControl();
  matches: any;
  isMatches: boolean;
  listItemHovered = false;
  isMobile: boolean = Boolean(false);
  isAppNotification = Boolean(true);
  profileIcon: string;
  notificationIcon: string;
  isNotification: boolean = Boolean(false);
  isProfile: boolean = Boolean(false);
  isNotificationPush: boolean;
  message: any;
  redirectionUrl: string;
  fcmToken: string;
  notificationList: NotificationModel[] = [];
  isNotificationSend: boolean = Boolean(false);
  profileRole: string;
  @ViewChild('myDiv') myDiv: ElementRef<HTMLElement>;
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.isMobile = this.winRef.isMobile();
  }

  @HostListener('document:click', ['$event']) onClick(event: {
    target: { id: string };
  }) {
    const elementId = event.target.id;
    if (elementId !== 'film-search' && elementId !== 'film-search-dropdown') {
      this.focus = false;
    } else {
      this.focus = true;
    }
  }

  constructor(
    private authService: AuthService,
    private router: Router,
    public toaster: ToasterService,
    private changeDetectorRef: ChangeDetectorRef,
    private filmService: UploadFilmService,
    public profileService: ViewerProfileService,
    private winRef: WindowRefService,
    private notificationService: NotificationService,
    private deviceService: DeviceDetectorService,
    public localStorageService: LocalStorageService,
    protected bigShortService : BigShortService,
  ) {
    this.isLoggedIn = this.authService.isLoggedIn.value;
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = this.router.url;
      }
    });
  }

  getScreensize(): void {
    this.isMobile = this.winRef.isMobile();
  }

  ngOnInit(): void {
    if (this.deviceService.os !== DeviceName.iOS && this.deviceService.os !== DeviceName.mac) {
      this.requestPermission();
      this.listen();
    }
    this.getScreensize();
    this.onSearch();
    this.authService.userSubject
      .pipe(takeUntil(this.unSubscribeAll))
      .subscribe((value) => {
        if (value) {
          this.user = value;
          this.changeDetectorRef.markForCheck();
        }
      });
    if (this.user) {
      this.getProfile();
      this.getNotificationList();
      this.changeDetectorRef.detectChanges();
    }
    window.addEventListener('scroll', () => {
      if (window.scrollY > 0) {
        this.isSticky = true;
      } else {
        this.isSticky = false;
      }
      this.changeDetectorRef.detectChanges();
    });
  }

  onSearch(): void {
    this.queryField.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((query: string) => {
          if (this.search) {
            return this.filmService.searchFilm(this.search, 1, 5, false).pipe(
              map((res: any) => (res && res.data) || []),
              tap((err) => {
                this.errorMessage =
                  (err && err.message) || 'Something goes wrong';

                return of([]);
              })
            );
          }
          return of([]);
        })
      )
      .subscribe((resp) => {
        this.matches = resp[0];
        this.isMatches = resp.length > 0 ? true : false;
        this.changeDetectorRef.detectChanges();
      });
  }

  getNotificationList(): void {
    this.notificationService
      .getNotificationList()
      .pipe(takeUntil(this.unSubscribeAll))
      .subscribe((res) => {
        this.notificationList = res.data;
        const readNotificationList = [];
        this.notificationList.forEach((element: any) => {
          if (element.read === false) {
            readNotificationList.push(element);
          }
        });
        if (readNotificationList.length > 0) {
          this.isNotificationPush = true;
        } else {
          this.isNotificationPush = false;
        }
      });
  }

  getProfile(): void {
    this.profileService
      .getProfile()
      .pipe(takeUntil(this.unSubscribeAll))
      .subscribe((res: any) => {
        this.userProfile = res.data;
        this.profileRole = this.userProfile.role;
        if (this.userProfile.isForceLogout) {
          if (this.userProfile.role !== 'FILM_MAKER') {
            this.onClickLogout();
          } else {
            this.authService._saveUser(res.data);
          }
        }
        if (this.fcmToken && this.userProfile.webFcmToken !== this.fcmToken) {
          this.sendFCMToken();
        }
      });
  }

  sendFCMToken(): void {
    const param = {
      email: this.userProfile.email,
      webFcmToken: this.fcmToken,
    };
    this.profileService
      .sendFCMToken(param)
      .pipe(take(1))
      .subscribe((res) => { });
  }

  onClickLogout(): void {
    this.authService.logout();
    this.router.navigate([Path.SignIn]);
  }

  openAbcMenu(): void {
    document.body.classList.add('abc-menu-open');
  }

  closeAbcMenu(): void {
    document.body.classList.remove('abc-menu-open');
  }

  openAbcSearch(): void {
    if (window.innerWidth < 768) {
      document.body.classList.add('abc-search-open');
    }
  }

  closeAbcSearch(): void {
    document.body.classList.remove('abc-search-open');
    this.search = '';
    const x = document.getElementsByTagName('ul')[2];
    x.style.display = 'none';
  }

  openSubmenu(el: any): void {
    el.target.parentElement.classList.toggle('open');
  }

  closeSubmenu(): void {
    const dropdownItems = document.querySelectorAll(
      '.abc-header .abc-has-submenu'
    );
    dropdownItems.forEach((dropdownItem) => {
      dropdownItem.classList.remove('open');
    });
  }

  sendNoticationInProfile(): void {
    this.profileService
      .sendNotificationInfo(this.isNotificationSend)
      .pipe(takeUntil(this.unSubscribeAll))
      .subscribe((response) => { });
  }

  switchRole(role: string): void {
    this.profileRole = role;
    this.isNotificationSend = this.userProfile?.isNotificationSend;
    this.switchrolesub = this.authService
      .switchUserRole(role, this.isNotificationSend)
      .pipe(takeUntil(this.unSubscribeAll))
      .subscribe((res) => {
        if (res.data && res.token) {
          this.authService._saveToken(res.token);
          this.authService._saveUser(res.data);
          this.sendNoticationInProfile();
          if (role === 'FILM_MAKER') {
            this.router.navigate(['app/filmmaker/dashboard']);
          } else if (role === 'VIEWER') {
            this.router.navigate(['/']);
          }
          this.toaster.success(res.message);
        }
      });
  }

  checkForRoles(): boolean {
    return (
      this.userProfile?.role === 'VIEWER' &&
      this.userProfile?.otherRoles &&
      this.userProfile?.otherRoles?.indexOf('FILM_MAKER') !== -1
    );
  }

  routeToFilmMaker(path: string): void {
    if (this.userProfile.isEmailVerified && this.userProfile.isPhoneVerified) {
      this.router.navigate([path]);
    } else {
      this.toaster.error('toaster.complete your verification');
      return;
    }
  }

  goToSearch(searchVal: any): void {
    this.router.navigate(['/app/search'], {
      queryParams: { key: searchVal },
    });
    this.closeAbcSearch();
  }

  hoverListItem(classList: DOMTokenList, isHover: boolean): void {
    isHover ? classList.add('active') : classList.remove('active');
  }

  closeDropDown(): void {
    this.focus = false;
  }

  onclick(): void {
    if (this.isNotification) {
      this.openNotification();
    }
    if (this.isProfile) {
      this.openProfile();
    }
  }

  closePopup(): void {
    this.isAppNotification = false;
    document.body.classList.remove('abc-app-notification-space');
  }

  openNotification(): void {
    this.isNotification = !this.isNotification;
    const iconLink = document.getElementById('abc-notification');
    if (this.isNotification) {
      this.isProfile = true;
      this.openProfile();
      iconLink?.classList.add('abc-show-dropdown');
    } else {
      iconLink?.classList.remove('abc-show-dropdown');
    }
  }

  markAllRead(): void {
    this.isNotificationPush = false;
    this.notificationService
      .markAllasRead(this.fcmToken)
      .pipe(takeUntil(this.unSubscribeAll))
      .subscribe((res) => {
        this.getNotificationList();
        this.isNotification = true;
        this.openNotification();
      });
  }

  onRedirect(notification: any): void {
    this.notificationService
      .readNotification(notification._id, this.fcmToken)
      .subscribe((res) => {
        this.getNotificationList();
        this.setRedirectionUrl(notification);
      });
  }

  setRedirectionUrl(notification: NotificationModel): void {
    let url = notification.redirectURL;
    if (notification.title === 'Download Now') {
      if (this.deviceService.os === DeviceName.iOS || this.deviceService.os === DeviceName.mac) {
        url = AppConstant.HomePageIconUrl.AppStoreLink;
      } else {
        url = AppConstant.HomePageIconUrl.PlayStoreLink;
      }
      window.open(url, '_blank');
    } else if (
      notification.filmId ||
      notification.genreId ||
      notification.languageId
    ) {
      if (notification.filmId) {
        url = '/app/movie-detail/' + notification.filmId;
        this.router.navigate([url]);
      }
      if (notification.genreId) {
        url = 'View-film-list/genre';
        this.router.navigate([url], {
          queryParams: { id: notification.genreId },
        });
      }
      if (notification.languageId) {
        url = '/View-film-list/language';
        this.router.navigate([url], {
          queryParams: { id: notification.languageId },
        });
      }
    } else if (notification.externalURL) {
      window.open(notification.externalURL, '_blank');
    } else if (url.includes('?')) {
      if (this.currentUrl.includes('app/profile/viewer')) {
        this.router.routeReuseStrategy.shouldReuseRoute = (): boolean => {
          return false;
        };
      }
      const data = url.split('?');
      this.router.navigate([data[0]], {
        queryParams: { activeTab: data[1] },
      });
    } else {
      this.router.navigate([url]);
    }
  }

  openProfile(): void {
    this.isProfile = !this.isProfile;
    const iconLink = document.getElementById('profile-icon');
    if (this.isProfile) {
      this.isNotification = true;
      this.openNotification();
      iconLink?.classList.add('abc-show-dropdown');
    } else {
      iconLink?.classList.remove('abc-show-dropdown');
    }
  }

  requestPermission(): void {
    const messaging = getMessaging();
    getToken(messaging, { vapidKey: environment.firebase.vapidKey })
      .then((currentToken) => {
        if (currentToken) {
          this.fcmToken = currentToken;
          this.localStorageService.set('FCMToken', this.fcmToken);
        }
      })
      .catch((err) => {
        console.log('toaster.firebase error', err);
      });
  }

  listen(): void {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      if (this.user.role === 'VIEWER') {
        this.message = payload;
        setTimeout(() => {
          this.getNotificationList();
        }, 2000);
        this.isNotificationPush = true;
        const el: HTMLElement = this.myDiv.nativeElement;
        el.click();
        this.toaster.info(
          this.message.notification.body,
          this.message.notification.title,
          {
            timeOut: 12000,
            extendedTimeOut: 1000,
          }
        );
        if (this.message?.data) {
          this.redirectionUrl = this.message?.data['gcm.notification.url'];
        }
      }
    });
  }

  trackByMethod(index: number): number {
    return index;
  }

  ngOnDestroy(): void {
    if (this.switchrolesub) {
      this.switchrolesub.unsubscribe();
    }
    if (this.suggestionsSub) {
      this.suggestionsSub.unsubscribe();
    }
    if (this.unSubscribeAll) {
      this.unSubscribeAll.next();
      this.unSubscribeAll.complete();
    }
  }
}
