import { HttpClient } from '@angular/common/http';
import { Injectable, Signal, WritableSignal, signal } from '@angular/core';
import { LocalStorageService } from '@app/core/services/local-storage.service';
import { User } from '@app/core/shared/user';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserCountryService {

  private baseUrl: string = environment.apiUrl;
  _countryCode: WritableSignal<string> = signal('IN');
  private readonly USER_ITEM = '_user';


  constructor(private http: HttpClient,
    public localStorageService: LocalStorageService,
  ) {

   }

   _getUser(): User {
    // tslint:disable-next-line:no-non-null-assertion
    return JSON.parse(this.localStorageService.get(this.USER_ITEM)) as User;
  }

  setCountryCode(value: string) {
    if (!value) return;
    this._countryCode.set(value.toUpperCase());
  }

  initialCountryCodeSetupByIp(): Promise<void> {
    const user = this._getUser();
    return new Promise((resolve, reject) => {
      if (!(user && user.countryCode)) {
        this.http.get('https://api.country.is').subscribe({
          next: (response: any) => {
            this.setCountryCode(response.country);
            resolve();
          },
          error: () => {
            this.getCountryCodeFromBackend().then(resolve).catch(reject);
          }
        });
      } else {
        this.setCountryCode(user.countryCode);
        return resolve();
      }
    });
  }

  private getCountryCodeFromBackend(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http.get('https://api.ipify.org?format=json').subscribe({
        next: (ipResponse: any) => {
          this.http.get<string>(`${this.baseUrl}/user/ipDetails/${ipResponse.ip}`).subscribe({
            next: (res: any) => {
              this.setCountryCode(res.data?.countryCode);
              resolve();
            },
            error: reject
          });
        },
        error: reject
      });
    });
  }

}
