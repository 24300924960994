import { CommonModule, DatePipe } from '@angular/common';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { environment } from '@environments/environment';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ToastrModule } from 'ngx-toastr';
import { MustMatchDirective } from './directive/match.validator';
import { GlobalErrorHandler } from './handlers';
import { JwtInterceptor, ServerErrorInterceptor } from './interceptors';
import { CustomDatePipePipe } from './pipe/custom-date-pipe.pipe';

@NgModule({
  declarations: [MustMatchDirective, CustomDatePipePipe],
  imports: [
    CommonModule,
    FormsModule,
    ToastrModule.forRoot({
      timeOut: 4000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient],
      },
    }),
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    NgSelectModule,
  ],
  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerErrorInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    DatePipe,
  ],
  exports: [
    FormsModule,
    MustMatchDirective,
    TranslateModule,
    ModalModule,
    NgSelectModule,
    CustomDatePipePipe,
    TooltipModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CoreModule { }

export function httpTranslateLoader(http: HttpClient): TranslateHttpLoader {
  const cdnUrl = environment.cdnUrl;
  const url = cdnUrl + '/assets/i18n/';
  return new TranslateHttpLoader(http, url, '.json');
}
